<template>
  <div>Loading Dashboard...</div>
</template>

<script>
export default {
  components: {},
  methods: {},
  beforeMount() {
    this.$router.push("/assessments");
  },
};
</script>
